<script>
import { getDocumentsForSelectedMonth } from "@/services/questions.js";
/**
 * Sales Analytics component
 */
export default {
  data() {
    return {
      series: [42, 26, 15],
      chartOptions: {
        chart: {
          height: 230,
          type: "donut",
        },
        labels: [
          this.$t("questions"),
          this.$t("contracts"),
          this.$t("consultant"),
        ],
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#eeb902", "#5664d2", "#1cbb8c"],
      },
      monthNames: [
        this.$t("Jan"),
        this.$t("Feb"),
        this.$t("Mar"),
        this.$t("Apr"),
        this.$t("May"),
        this.$t("Jun"),
        this.$t("Jul"),
        this.$t("Aug"),
        this.$t("Sep"),
        this.$t("Oct"),
        this.$t("Nov"),
        this.$t("Dec"),
      ],
      view: true,
      selectedMonth: "",
    };
  },
  props: {
    data: { Type: Object, default: () => {} },
  },
  methods: {
    async getForSelectedMonth(month) {
      // Filter documents for the current year
      const documentsForCurrentYear = this.data?.filter(
        (doc) => doc.month === month
      );
      let questionsDocumentsForCurrentYear;
      try {
        questionsDocumentsForCurrentYear = await getDocumentsForSelectedMonth(
          month
        );
      } catch (error) {
        console.error("Error getting documents:", error);
      }
      // console.log(questionsDocumentsForCurrentYear);
      return { documentsForCurrentYear, questionsDocumentsForCurrentYear };
    },
    async percentage(totalGet, oneGet) {
      // Calculate the percentage
      if (totalGet === 0) {
        return 0; // Avoid division by zero
      }
      if (!totalGet) {
        return 0;
      }
      return ((oneGet / totalGet) * 100).toFixed(2);
    },
  },

  created() {
    const month = new Date().getMonth();
    this.selectedMonth = this.monthNames[month];
  },
  watch: {
    async selectedMonth() {
      this.view = false;
      const selectedMonth1 =
        this.monthNames.findIndex((el) => el === this.selectedMonth) + 1;
      let selectedMonth = await this.getForSelectedMonth(selectedMonth1);

      // console.log(selectedMonth);

      const total_get =
        +selectedMonth?.documentsForCurrentYear[0]?.total_no_paid +
        +selectedMonth.questionsDocumentsForCurrentYear.length;
      const total_get_contracts =
        selectedMonth?.documentsForCurrentYear[0]?.total_no_contracts;
      const total_get_consultants =
        selectedMonth?.documentsForCurrentYear[0]?.total_no_consultants;

      const consultantPercentage = await this.percentage(
        total_get,
        total_get_consultants
      );
      const contractPercentage = await this.percentage(
        total_get,
        total_get_contracts
      );
      const questionPercentage = await this.percentage(
        total_get,
        selectedMonth.questionsDocumentsForCurrentYear.length
      );

      // All asynchronous operations have completed at this point
      this.series[0] = +questionPercentage;
      this.series[2] = +consultantPercentage;
      this.series[1] = +contractPercentage;
      this.view = true;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="float-right">
        <select class="custom-select custom-select-sm" v-model="selectedMonth">
          <option v-for="month in monthNames" :key="month">
            {{ month }}
          </option>
        </select>
      </div>
      <h4 class="card-title mb-4">{{ $t("dashboard.work_analytics.text") }}</h4>

      <div id="donut-chart" class="apex-charts"></div>
      <apexchart
        v-if="view"
        class="apex-charts"
        height="230"
        dir="ltr"
        :series="series"
        :options="chartOptions"
      ></apexchart>
      <div class="loader" v-if="!view"></div>
      <div class="row">
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i>
              {{ $t("contracts") }}
            </p>
            <h5>{{ series[1] }} %</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i>
              {{ $t("consultations") }}
            </p>
            <h5>{{ series[2] }} %</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-warning font-size-10 mr-1"></i>
              {{ $t("questions") }}
            </p>
            <h5>{{ series[0] }} %</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.loader {
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #3498db; /* Blue border on top */
  border-radius: 50%; /* Make it circular */
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Spin animation */
  margin-inline-start: 48%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
