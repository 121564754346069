<script>
/**
 * Widget Component
 */
export default {
  data() {
    return {
      statData: [
        {
          title: this.$t("dashboard.number_of_clients.text"),
          icon: "ri-account-circle-line",
          value: "1452",
        },
        {
          title: this.$t("dashboard.number_of_agents.text"),
          icon: "ri-account-circle-line",
          value: "1452",
        },
        {
          title: this.$t("dashboard.number_of_contracts.text"),
          icon: "ri-file-list-3-line",
          value: "",
        },
        {
          title: this.$t("dashboard.number_of_consultation.text"),
          icon: "ri-calendar-2-line",
          value: "1452",
        },
        {
          title: this.$t("dashboard.number_of_questions.text"),
          icon: "ri-questionnaire-line",
          value: "1452",
        },
        {
          title: this.$t("dashboard.sales_revenue.text"),
          icon: "ri-creative-commons-nc-line",
          value: "$ 38452",
        },
      ],
    };
  },
  props: {
    noOfAgents: { Type: Number, default: 0 },
    noOfConsultants: { Type: Number, default: 0 },
    noOfContracts: { Type: Number, default: 0 },

    noOfFinancials: { Type: Number, default: 0 },

    noOfQuestions: { Type: Number, default: 0 },

    noOfUsers: { Type: Number, default: 0 },

    totalGetOverAll: { Type: Number, default: 0 },
  },

  computed: {
    widget() {
      return [
        {
          title: this.$t("dashboard.number_of_clients.text"),
          icon: "ri-account-circle-line",
          value: this.noOfUsers,
          linkName:'Allclient',
        },
        {
          title: this.$t("dashboard.number_of_agents.text"),
          icon: "ri-account-circle-line",
          value: this.noOfAgents,
          linkName:'Allagent',
        },
        {
          title: this.$t("dashboard.number_of_contracts.text"),
          icon: "ri-file-list-3-line",
          value: this.noOfContracts,
          linkName:'Allcontract',
        },
        {
          title: this.$t("dashboard.number_of_consultation.text"),
          icon: "ri-calendar-2-line",
          value: this.noOfConsultants,
          linkName:'Allconsultant',
        },
        {
          title: this.$t("dashboard.number_of_questions.text"),
          icon: "ri-questionnaire-line",
          value: this.noOfQuestions,
          linkName:'Allquestion',
        },
        {
          title: this.$t("dashboard.sales_revenue.text"),
          icon: "ri-creative-commons-nc-line",
          value: this.totalGetOverAll,
          linkName:'Allpayment',
        },
      ];
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in widget" :key="index">
      <router-link :to="{name:data.linkName}"  class="link-card">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
                <h4 class="mb-0">{{ data.value }}</h4>
              </div>
              <div class="text-primary">
                <i :class="`${data.icon} font-size-24`"></i>
              </div>
            </div>
          </div>
        </div>
      </router-link>

    </div>
  </div>
  <!-- end row -->
</template>
<style scoped>
.link-card{
  color:inherit;
}
</style>