<script>
/**
 * Earning Reports component
 */
export default {
  data() {
    return {
      selectedMonth: "",
      contractEarning: {
        series: [72],
        chartOptions: {
          chart: {
            type: "radialBar",
            wight: 60,
            height: 60,
            sparkline: {
              enabled: true,
            },
          },
          // dataLabels: {
          //   enabled: true,
          // },
          colors: ["#5664d2"],
          stroke: {
            lineCap: "round",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "70%",
              },
              track: {
                margin: 0,
              },
              dataLabels: {
                show: false,
              },
            },
          },
        },
      },
      consultantEarning: {
        series: [65],
        chartOptions: {
          chart: {
            type: "radialBar",
            wight: 60,
            height: 60,
            sparkline: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#1cbb8c"],
          stroke: {
            lineCap: "round",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "70%",
              },
              track: {
                margin: 0,
              },

              dataLabels: {
                show: false,
              },
            },
          },
        },
      },
      monthNames: [
        this.$t("Jan"),
        this.$t("Feb"),
        this.$t("Mar"),
        this.$t("Apr"),
        this.$t("May"),
        this.$t("Jun"),
        this.$t("Jul"),
        this.$t("Aug"),
        this.$t("Sep"),
        this.$t("Oct"),
        this.$t("Nov"),
        this.$t("Dec"),
      ],
      selectedMonthConsultant: "",
      selectedMonthContract: "",
    };
  },
  props: {
    data: { Type: Object, default: () => {} },
  },
  computed: {
    months() {
      // Get the current year
      const currentYear = new Date().getFullYear();
      // Filter documents for the current year
      const documentsForCurrentYear = this.data?.filter(
        (doc) => doc.year === currentYear
      );
      return documentsForCurrentYear;
    },

    withMonthNames() {
      const documentsWithMonthNames = this.months?.map((doc) => {
        const monthName = this.monthNames[doc.month - 1]; // Subtract 1 as month numbers are 1-based
        return { ...doc, monthName };
      });
      return documentsWithMonthNames;
    },
  },
  methods: {
    percentage(totalGet, oneGet) {
      // Calculate the percentage
      if (totalGet === 0) {
        return 0; // Avoid division by zero
      }
      if (!totalGet) {
        return 0; // Avoid division by zero
      }
      return ((oneGet / totalGet) * 100).toFixed(2);
    },
    getForSelectedMonth(month) {
      // Filter documents for the current year
      const documentsForCurrentYear = this.data?.filter(
        (doc) => doc.month === month
      );
      return documentsForCurrentYear;
    },
  },
  watch: {
    selectedMonth() {
      // This function will be called when selectedMonth changes
      // You can perform actions here based on the new and old values
      //console.log('Selected month changed from', oldValue, 'to', newValue);
      const selectedMonth1 =
        this.monthNames.findIndex((el) => el === this.selectedMonth) + 1;
      const selectedMonth = this.getForSelectedMonth(selectedMonth1);
      // console.log(selectedMonth);
      const total_get = selectedMonth[0]?.total_get;
      const total_get_contracts = selectedMonth[0]?.total_get_contracts;
      const total_get_consultants = selectedMonth[0]?.total_get_consultants;
      const consultantPercentage = this.percentage(
        total_get,
        total_get_consultants
      );
      const contractPercentage = this.percentage(
        total_get,
        total_get_contracts
      );
      this.selectedMonthConsultant = selectedMonth[0]?.total_get_consultants
        ? selectedMonth[0]?.total_get_consultants
        : 0;
      this.selectedMonthContract = selectedMonth[0]?.total_get_contracts
        ? selectedMonth[0]?.total_get_contracts
        : 0;
      this.consultantEarning.series = [+consultantPercentage];
      this.contractEarning.series = [+contractPercentage];
      // Call your custom actions or methods here
    },
    data() {
      // This function will be called when selectedMonth changes
      // You can perform actions here based on the new and old values
      //console.log('Selected month changed from', oldValue, 'to', newValue);
      this.selectedMonth = this.withMonthNames[0];
      // Call your custom actions or methods here
    },
  },
  created() {
    const month = new Date().getMonth();
    this.selectedMonth = this.monthNames[month];
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="float-right">
        <select class="custom-select custom-select-sm" v-model="selectedMonth">
          <option v-for="month in monthNames" :key="month" :value="month">
            {{ month }}
          </option>
        </select>
      </div>

      <h4 class="card-title mb-4">
        {{ $t("dashboard.earning_reports.text") }}
      </h4>
      <div class="text-center">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="mb-3">
                <apexchart
                  class="apex-charts"
                  height="235"
                  wight="60"
                  dir="ltr"
                  :series="contractEarning.series"
                  :options="contractEarning.chartOptions"
                ></apexchart>
              </div>
              <p class="text-muted text-truncate mb-2">{{ $t("contracts") }}</p>
              <h5 class="mb-0">{{ selectedMonthContract }} {{ $t("KWD") }}</h5>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="mt-5 mt-sm-0">
              <div class="mb-3">
                <apexchart
                  class="apex-charts"
                  height="235"
                  wight="60"
                  dir="ltr"
                  :series="consultantEarning.series"
                  :options="consultantEarning.chartOptions"
                ></apexchart>
              </div>

              <p class="text-muted text-truncate mb-2">
                {{ $t("consultations") }}
              </p>
              <h5 class="mb-0">
                {{ selectedMonthConsultant }} {{ $t("KWD") }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
