<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Stat from "./widget";
import RevenueAnalytics from "./revenue";
import SalesAnalytics from "./sales-analytics";
import EarningReport from "./earning";
// import Sources from "./sources";
// import RecentActivity from "./recent-activity";
// import RevenueLocation from "./revenue-location";
// import Chat from "./chat";
import Transaction from "./transaction";
import { GetDashboard } from "../../../services/dashboard";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    RevenueAnalytics,
    SalesAnalytics,
    EarningReport,
    // Sources,
    // RecentActivity,
    // RevenueLocation,
    // Chat,
    Transaction,
  },
  data() {
    return {
      title: this.$t("dashboard.dashboard.text"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("dashboard.dashboard.text"),
          active: true,
        },
      ],
      data: {},
      view: false,
    };
  },
  created() {
    GetDashboard().then((data) => {
      this.data = data;
      this.view = true;
    });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="view">
      <div class="col-xl-12">
        <Stat
          :noOfAgents="data?.noOfAgents"
          :noOfConsultants="data?.noOfConsultants"
          :noOfContracts="data?.noOfContracts"
          :noOfQuestions="data?.noOfQuestions"
          :noOfUsers="data?.noOfUsers"
          :totalGetOverAll="data?.totalGetOverAll"
        />

        <RevenueAnalytics :data="data?.financials" />

      </div>
      <div class="col-xl-6">
        <SalesAnalytics :data="data?.financials" />
      </div>
      <div class="col-xl-6">
        <EarningReport :data="data?.financials" />
      </div>
      <div class="col-xl-12">
        <Transaction :data="data?.financials" />
      </div>
    </div>
    <div class="loader" v-if="!view"></div>

    <!-- <div class="row">
      <div class="col-lg-8"></div>
      <div class="col-lg-8"> -->
    <!-- <RecentActivity /> -->
    <!-- </div> -->
    <!-- <div class="col-lg-4"></div> -->
    <!-- </div> -->
    <!-- <div class="row">
      <div class="col-lg-4"><Chat /></div>
      <div class="col-lg-8"></div>
    </div> -->
  </Layout>
</template>

<style>
.loader {
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #3498db; /* Blue border on top */
  border-radius: 50%; /* Make it circular */
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Spin animation */
  margin-top: 25%;
  margin-inline-start: 48%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
