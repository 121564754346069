import axios from "axios";
import Swal from "sweetalert2";
// Define the URL of your Firebase Cloud Function
const firebaseFunctionURL =
  "https://us-central1-law-office-flutterflow.cloudfunctions.net/app/dashboard";

export function GetDashboard() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${firebaseFunctionURL}`)
      .then((res) => {
        // console.log(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error getting dashboard:", err);
        message(err);

        reject(err);
      });
  });
}
function message(msg) {
  Swal.fire({
    position: "center",
    icon: "error",
    title: msg,
    showConfirmButton: true,
  });
}
