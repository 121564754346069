<script>
import moment from "moment";
/**
 * Transactions component
 */
export default {
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "date",
      sortDesc: false,
      tableData: [],
      fields: [
        { key: "type", sortable: true, label: this.$t("type") },
        {
          key: "createdAt",
          sortable: true,
          label: this.$t("date"),
        },
        {
          key: "amount",
          sortable: true,
          label: this.$t("total"),
          formatter: (value) => {
            return value + " " + this.$t("KWD");
          },
        },
      ],
    };
  },
  props: {
    data: { Type: Object, default: () => {} },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.monthdoc?.length;
    },
    monthdoc() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear(); // e.g., 2023
      const currentMonth = currentDate.getMonth() + 1; // Note: Months are zero-based, so add 1

      // Filter the objects for the current year and month
      const filteredData = this.data?.filter((item) => {
        const itemMonth = item.month;
        const itemYear = item.year;

        return itemMonth === currentMonth && itemYear === currentYear;
      });
      const history = filteredData[0]?.history?.map((item) => {
        // Add a new attribute 'age' to each object

        return {
          ...item,
          createdAt: moment(
            this.nanosecondsToISO8601(item.created_at._seconds)
          ).format("YYYY/MM/DD"), // You can replace this with your logic
        };
      });
      return history;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.monthdoc?.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    nanosecondsToISO8601(seconds) {
      const milliseconds = seconds * 1000; // Convert nanoseconds to milliseconds
      const date = new Date(milliseconds);
      return date;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">
        {{ $t("dashboard.latest_transactions.text") }}
      </h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              {{ $t("show") }}&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;{{ $t("entries") }}
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              {{ $t("search") }}:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="monthdoc"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(paymentstatus)="row">
            <div
              class="badge font-size-12"
              :class="{
                'badge-soft-danger': `${row.value}` === 'Chargeback',
                'badge-soft-success': `${row.value}` === 'Paid',
                'badge-soft-warning': `${row.value}` === 'Unpaid',
              }"
            >
              {{ row.value }}
            </div>
          </template>

          <template v-slot:cell(action)>
            <a
              href="javascript:void(0);"
              class="mr-3 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="Edit"
            >
              <i class="mdi mdi-pencil font-size-18"></i>
            </a>
            <a
              href="javascript:void(0);"
              class="text-danger"
              v-b-tooltip.hover
              title="Delete"
            >
              <i class="mdi mdi-trash-can font-size-18"></i>
            </a>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
