<script>
/**
 * Revenue Analytics component
 */
export default {
  data() {
    return {
      series: [
        {
          name: "2020",
          type: "column",
          data: [],
        },
        // {
        //   name: "2019",
        //   type: "line",
        //   data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16],
        // },
      ],
      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 3],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: [],
      },
      monthNames: [
        this.$t("Jan"),
        this.$t("Feb"),
        this.$t("Mar"),
        this.$t("Apr"),
        this.$t("May"),
        this.$t("Jun"),
        this.$t("Jul"),
        this.$t("Aug"),
        this.$t("Sep"),
        this.$t("Oct"),
        this.$t("Nov"),
        this.$t("Dec"),
      ],
      weekNames: [
        this.$t("sun"),
        this.$t("mon"),
        this.$t("tue"),
        this.$t("wed"),
        this.$t("thu"),
        this.$t("fri"),
        this.$t("sat"),
      ],
      selectedview: "month",
      view: true,
    };
  },
  props: {
    data: { Type: Array, default: () => [] },
  },
  watch: {
    selectedview(newValue) {
      // This function will be called when selectedMonth changes
      // You can perform actions here based on the new and old values
      // //console.log('Selected month changed from', oldValue, 'to', newValue);
      //because the plot is not dynamic so we have to disable it then rerender it after changing the data
      this.view = false;
      //console.log("1");
      if (newValue === "today") {
        //console.log("I'm doing today");
        const hoursOfDay = [];
        //console.log("2");
        for (let hour = 0; hour < 24; hour++) {
          const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
          hoursOfDay.push(`${formattedHour}:00`);
        }
        this.series[0].data = this.todayIncome.data;
        this.chartOptions.labels = hoursOfDay;

        // Create a Promise to execute the next step after the current code is done
        new Promise((resolve) => resolve()).then(() => {
          this.view = true;
          //console.log("3");
        });
      } else if (newValue === "month") {
        //console.log("I'm doing month");
        this.series[0].data = this.monthlyIncome.data;
        this.chartOptions.labels = this.monthNames;
        //console.log(this.chartOptions.labels);
        //console.log(this.series[0].data);
        // Create a Promise to execute the next step after the current code is done
        new Promise((resolve) => resolve()).then(() => {
          this.view = true;
          //console.log("3");
        });
      } else if (newValue === "daily") {
        //console.log("I'm doing daily");
        this.series[0].data = this.weekData.days;
        this.chartOptions.labels = this.weekNames;
        //console.log(this.chartOptions.labels);
        //console.log(this.series[0].data);
        // Create a Promise to execute the next step after the current code is done
        new Promise((resolve) => resolve()).then(() => {
          this.view = true;
          //console.log("3");
        });
      } else {
        //console.log("I'm doing week");
        this.series[0].data = this.weeklyIncome.data;
        this.chartOptions.labels = [
          this.$t("week1"),
          this.$t("week2"),
          this.$t("week3"),
          this.$t("week4"),
        ];
        // Create a Promise to execute the next step after the current code is done
        new Promise((resolve) => resolve()).then(() => {
          this.view = true;
          //console.log("3");
        });
      }
    },
  },
  computed: {
    currentWeek() {
      const currentDate = new Date();
      const currentDay = currentDate.getDate();
      if (currentDay < 8) {
        return 0;
      } else if (currentDay < 15) {
        return 1;
      } else if (currentDay < 22) {
        return 2;
      } else {
        return 3;
      }
    },
    todayIncome() {
      // Calculate daily income
      let hours = new Array(24).fill(0);
      let total = 0;
      const today = new Date(); // Get today's date\
      // console.log(
      //   this.monthdoc[0]?.history,
      //   today.getDate(),
      //   today.getMonth() + 1,
      //   today.getFullYear()
      // );
      const month = today.getMonth() + 1;
      const filteredObjects = this.monthdoc[0]?.history?.filter((object) => {
        return (
          object.day === today.getDate() &&
          object.month === month &&
          object.year === today.getFullYear()
        );
      });
      console.log(filteredObjects);
      filteredObjects.forEach((element) => {
        const hour = new Date(
          this.nanosecondsToISO8601(element.created_at._seconds)
        ).getHours();
        hours[hour] += element.amount;
        total += element.amout;
      });
      return { data: hours, total };
    },

    weeklyIncome() {
      let week1 = 0;
      let week2 = 0;
      let week3 = 0;
      let week4 = 0;
      this.monthdoc[0]?.history?.forEach((element) => {
        // console.log("weekly", element);
        if (element.day < 8) {
          week1 += element.amount;
        } else if (element.day < 15) {
          week2 += element.amount;
        } else if (element.day < 22) {
          week3 += element.amount;
        } else {
          week4 += element.amount;
        }
      });
      return {
        data: [week1, week2, week3, week4],
        total:
          this.monthdoc[0]?.total_get == 0 ||
          this.monthdoc[0]?.total_get == null
            ? 0
            : this.monthdoc[0]?.total_get,
      };
    },
    monthlyIncome() {
      let months = new Array(12).fill(0);
      const myArray = this.yeardocs; // Renamed 'Array' to 'myArray'
      let total = 0;

      myArray.forEach((el) => {
        total += el?.total_get;
        months[el.month - 1] = el?.total_get;
      });

      return { data: months, total };
    },

    // withMonthNames() {
    //   const documentsWithMonthNames = this.yeardocs?.map((doc) => {
    //     const monthName = this.monthNames[doc.month - 1]; // Subtract 1 as month numbers are 1-based
    //     return { ...doc, monthName };
    //   });
    //   return documentsWithMonthNames;
    // },
    yeardocs() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear(); // e.g., 2023
      // const currentMonth = currentDate.getMonth() + 1; // Note: Months are zero-based, so add 1

      // Filter the objects for the current year and month
      const filteredData = this.data.filter((item) => {
        const itemYear = item.year;

        return itemYear === currentYear;
      });
      return filteredData;
    },
    monthdoc() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear(); // e.g., 2023
      const currentMonth = currentDate.getMonth() + 1; // Note: Months are zero-based, so add 1

      // Filter the objects for the current year and month
      const filteredData = this.data.filter((item) => {
        const itemMonth = item.month;
        const itemYear = item.year;

        return itemMonth === currentMonth && itemYear === currentYear;
      });
      return filteredData;
    },
    weekData() {
      let days = new Array(7).fill(0);
      const todayWeek = new Date().getDay();
      const todayMonth = new Date().getDate();
      //to get the date of start week
      const startRange = todayMonth - todayWeek;
      const endRange = todayMonth - todayWeek + 6;
      // console.log(startRange);
      // console.log(endRange);
      const weekArray = [];
      this.monthdoc[0].history?.forEach((el) => {
        //console.log(date);
        if (el.day > startRange && el.day <= endRange) {
          // console.log("this week", el.day);
          weekArray.push(el);
        }
      });

      weekArray.forEach((el) => {
        days[
          new Date(this.nanosecondsToISO8601(el.created_at._seconds)).getDay()
        ] += el.amount;
        // console.log(day);
        // console.log("date", (day % 7) - 1);
      });

      return { weekArray, days };
    },
  },
  methods: {
    nanosecondsToISO8601(seconds) {
      const milliseconds = seconds * 1000; // Convert nanoseconds to milliseconds
      const date = new Date(milliseconds);
      return date;
    },
  },
  created() {
    //init the first plot data
    //console.log(this.weekData);
    this.series[0].data = this.monthlyIncome.data;
    this.chartOptions.labels = this.monthNames;
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="float-right d-none d-md-inline-block">
        <select class="custom-select custom-select-sm" v-model="selectedview">
          <option value="today">{{ $t("today") }}</option>
          <option value="daily">{{ $t("daily") }}</option>
          <option value="week">{{ $t("weekly") }}</option>
          <option selected value="month">{{ $t("monthly") }}</option>
        </select>
      </div>
      <h4 class="card-title mb-4">
        {{ $t("dashboard.revenue_analytics.text") }}
      </h4>
      <div v-if="view">
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="280"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>

    <div class="card-body border-top text-center">
      <div class="row">
        <div class="col-sm-4">
          <p class="text-muted text-truncate mb-0">{{ $t("this_month") }} :</p>
          <div class="d-inline-flex">
            <h5 class="mr-2">{{ weeklyIncome.total }} {{ $t("KWD") }}</h5>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">{{ $t("this_year") }}:</p>
            <div class="d-inline-flex">
              <h5 class="mb-0 mr-2">
                {{ monthlyIncome.total }}{{ $t("KWD") }}
              </h5>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">{{ $t("this_week") }} :</p>
            <div class="d-inline-flex">
              <h5 class="mb-0">
                {{ weeklyIncome.data[currentWeek] }}{{ $t("KWD") }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
